import React, { useEffect } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
  MeetingConsumer,
} from "@videosdk.live/react-sdk";
import { VideoSDKMeeting } from "@videosdk.live/rtc-js-prebuilt";
import { useParams } from 'react-router-dom';

function Support1() {
  const p=useParams();
  var {meetingId}=p;

  useEffect(() => {
    var script = document.createElement("script");
    script.type = "text/javascript";

    script.addEventListener("load", function (event) {
      const config = {
        name: "Support User",
        // meetingId: "ioks-rgru-c904",
        meetingId: meetingId,
        apiKey: "378c5971-9f73-494e-ba84-1d46661a5947",

        containerId: null,
        // pin: true,

        micEnabled: true,
        webcamEnabled: true,
        participantCanToggleSelfWebcam: true,
        participantCanToggleSelfMic: true,

        chatEnabled: true,
        screenShareEnabled: true,

        permissions: {
          removeParticipant: true,
          endMeeting: true,
          pin: true,
          changeLayout: true,
        },
        pin: {
          allowed: true,
          layout: "SIDEBAR",
        },
        layout: {
          type: "SIDEBAR", // "SPOTLIGHT" | "SIDEBAR" | "GRID"
          priority: "PIN", // "SPEAKER" | "PIN",
          // gridSize: 3,
        },

        /*

     Other Feature Properties
      
      */
      };

      const meeting = new VideoSDKMeeting();
      meeting.init(config);
    });

    script.src =
      "https://sdk.videosdk.live/rtc-js-prebuilt/0.3.23/rtc-js-prebuilt.js";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);

  return (
    <>
      <h1>SignAble's Support</h1>
    </>
  );
}

export default Support1;
